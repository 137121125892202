<template>
	<div id="app-choose-options">
		<back-step
			v-if="false"
			:step-number="4"
		></back-step>
		<app-stepper :step-number="4"></app-stepper>

		<div>
			<div class="row flex-column align-items-center mainBox">
				<h2 class="col-12 mb-5 text-center text-format-1">
					En el caso de que nos des tu consentimiento, marcando las casillas incluidas a
					continuación, procederemos al tratamiento de tus datos personales con las siguientes
					finalidades:
				</h2>
				<form class="d-flex flex-column align-items-center">
					<div class="d-flex flex-column justify-content-center mb-5">
						<div class="d-flex justify-content-center space-error mb-3">
							<span
								v-if="hasError"
								class="text-center text-danger"
							>
								Para continuar, debe seleccionar algunas de las opciones.
							</span>
						</div>
						<div class="container pb-3 d-flex justify-content-end pl-4 pr-4">
							<p class="checkbox-description block m-0 tf text-justify">
								Tratar mis datos para
								<b>todas las finalidades</b>
								que se indican a continuación.
							</p>
							<div class="pt-1 pl-3 list-group list-group-horizontal">
								<label
									class="containerBox"
									tabindex="0"
								>
									<input
										v-model="consents"
										type="radio"
										:value="{
											commercialProfileConsent: 'yes',
											dataTransferConsent: 'yes',
											thirdPartiesDataTransferConsent: 'yes',
										}"
										@change="
											consentsError = {
												commercialProfileConsentError: false,
												dataTransferConsentError: false,
												thirdPartiesDataTransferConsentError: false,
											}
										"
									/>
									<span class="checkmarkBF"></span>
									<p>Si</p>
								</label>
								<label
									class="containerBox"
									tabindex="0"
								>
									<input
										v-model="consents"
										type="radio"
										:value="{
											commercialProfileConsent: 'no',
											dataTransferConsent: 'no',
											thirdPartiesDataTransferConsent: 'no',
										}"
										@change="
											consentsError = {
												commercialProfileConsentError: false,
												dataTransferConsentError: false,
												thirdPartiesDataTransferConsentError: false,
											}
										"
									/>
									<span class="checkmarkBF"></span>
									<p>No</p>
								</label>
							</div>
						</div>
						<div class="container pb-3 d-flex justify-content-center pl-4 pr-4">
							<p class="checkbox-description block m-0 tf text-justify">
								Elaborar, en base a la información facilitada por terceros (identificados en
								la Política de Privacidad en la web
								<a
									class="d-inline-flex"
									:href="$store.state.app.url"
									target="_blank"
									:title="$store.state.app.url"
									tabindex="0"
								>
									www.cbnk.es),
								</a>
								un perfil comercial más completo, permitiendo ofrecerme servicios más
								personalizados y mejores, mediante el uso de decisiones automatizadas.
							</p>
							<div class="pt-1 pl-3 list-group list-group-horizontal">
								<label
									class="containerBox"
									tabindex="0"
								>
									<input
										v-model="consents.commercialProfileConsent"
										type="radio"
										value="yes"
										@change="consentsError.commercialProfileConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{ '--error': consentsError.commercialProfileConsentError }"
									></span>
									<p>Si</p>
								</label>
								<label
									class="containerBox"
									tabindex="0"
								>
									<input
										v-model="consents.commercialProfileConsent"
										type="radio"
										value="no"
										@change="consentsError.commercialProfileConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{ '--error': consentsError.commercialProfileConsentError }"
									></span>
									<p>No</p>
								</label>
							</div>
						</div>
						<div class="container pb-3 d-flex justify-content-center pl-4 pr-4">
							<p class="checkbox-description block m-0 tf text-justify">
								Ceder mis datos a entidades del grupo CBNK, participadas y colaboradoras
								(las cuales pueden consultarse en
								<a
									class="d-inline-flex"
									:href="$store.state.app.url"
									target="_blank"
									:title="$store.state.app.url"
									tabindex="0"
								>
									www.cbnk.es)
								</a>
								para recibir comunicaciones comerciales, por cualquier medio, de productos y
								servicios comercializados por las mismas y personalizadas de acuerdo con mi
								perfil comercial.
							</p>
							<div class="pt-1 pl-3 list-group list-group-horizontal">
								<label class="containerBox">
									<input
										v-model="consents.dataTransferConsent"
										type="radio"
										value="yes"
										@change="consentsError.dataTransferConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{ '--error': consentsError.dataTransferConsentError }"
									></span>
									<p>Si</p>
								</label>
								<label class="containerBox">
									<input
										v-model="consents.dataTransferConsent"
										type="radio"
										value="no"
										@change="consentsError.dataTransferConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{ '--error': consentsError.dataTransferConsentError }"
									></span>
									<p>No</p>
								</label>
							</div>
						</div>
						<div class="container pb-3 d-flex justify-content-center pl-4 pr-4">
							<p class="checkbox-description block m-0 tf text-justify">
								Enviarme comunicaciones comerciales, por cualquier medio, sobre productos y
								servicios distintos de los que tengo contratados y adaptadas a mi perfil
								comercial, ofertados tanto por la Entidad como por terceros. Los tipos de
								productos y servicios están identificados en la
								<a
									:href="$store.state.app.privacyPolicyURL"
									target="_blank"
									title="Política de Privacidad"
									tabindex="0"
								>
									Política de Privacidad
								</a>
							</p>
							<div class="pt-1 pl-3 list-group list-group-horizontal">
								<label class="containerBox">
									<input
										v-model="consents.thirdPartiesDataTransferConsent"
										type="radio"
										value="yes"
										@change="consentsError.thirdPartiesDataTransferConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{
											'--error': consentsError.thirdPartiesDataTransferConsentError,
										}"
									></span>
									<p>Si</p>
								</label>
								<label class="containerBox">
									<input
										v-model="consents.thirdPartiesDataTransferConsent"
										type="radio"
										value="no"
										@change="consentsError.thirdPartiesDataTransferConsentError = false"
									/>
									<span
										class="checkmarkBF"
										:class="{
											'--error': consentsError.thirdPartiesDataTransferConsentError,
										}"
									></span>
									<p>No</p>
								</label>
							</div>
						</div>
					</div>
					<div class="confirm-button-group">
						<button
							class="button-confirm mb-5"
							type="submit"
							:disabled="isButtonDisabled"
							data-testid="confirm-button"
							@click.prevent="submit"
						>
							CONTINUAR
						</button>
					</div>
					<app-mobile-call></app-mobile-call>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';
import AppMobileCall from '@/components/app-mobile-call';
import BackStep from '@/components/back-step';
import { mapState } from 'vuex';

export default {
	name: 'app-choose-options',

	components: {
		AppStepper,
		AppMobileCall,
		BackStep,
	},

	data() {
		return {
			consentsError: {
				commercialProfileConsentError: false,
				dataTransferConsentError: false,
				thirdPartiesDataTransferConsentError: false,
			},
			consents: {
				commercialProfileConsent: null,
				dataTransferConsent: null,
				thirdPartiesDataTransferConsent: null,
			},
			isButtonDisabled: false,
		};
	},

	computed: {
		...mapState('onboarding', ['hasDoneIdentification', 'clientInformation']),

		hasError({ consentsError }) {
			return Object.entries(consentsError).some(([, value]) => Boolean(value));
		},
	},

	methods: {
		submit() {
			const { commercialProfileConsent, dataTransferConsent, thirdPartiesDataTransferConsent } =
				this.consents;

			this.consentsError = {
				...this.consentsError,
				commercialProfileConsentError: !commercialProfileConsent,
				dataTransferConsentError: !dataTransferConsent,
				thirdPartiesDataTransferConsentError: !thirdPartiesDataTransferConsent,
			};

			if (commercialProfileConsent && dataTransferConsent && thirdPartiesDataTransferConsent) {
				const acceptsCommercialProfile = commercialProfileConsent === 'yes';
				const acceptsDataTransfer = dataTransferConsent === 'yes';
				const acceptsThirdPartiesDataTransfer = thirdPartiesDataTransferConsent === 'yes';

				this.isButtonDisabled = true;
				this.$store
					.dispatch('onboarding/saveProcess', {
						acceptsCommercialProfile,
						acceptsDataTransfer,
						acceptsThirdPartiesDataTransfer,
					})
					.then(async () => {
						if (this.hasDoneIdentification) {
							const { data: offices } = await this.getOfficesData();

							this.$router.push({
								name: 'app-election-office',
								params: { offices },
							});
						} else {
							this.$router.push('app-choose-options');
						}
					})
					.catch(() => {})
					.finally(() => {
						this.isButtonDisabled = false;
					});
			}
		},

		recoverPendingData() {
			const { commercialProfile, dataTransfer, thirdPartyDataTransfer } =
				this.clientInformation.accepts;

			this.consents = {
				commercialProfileConsent: commercialProfile ? 'yes' : 'no',
				dataTransferConsent: dataTransfer ? 'yes' : 'no',
				thirdPartiesDataTransferConsent: thirdPartyDataTransfer ? 'yes' : 'no',
			};
		},
		async getOfficesData() {
			const res = await this.$store.dispatch(
				'onboarding/getOffices',
				this.$store.state.onboarding.id
			);

			return res.data;
		},
	},

	async mounted() {
		const clientHasInformation = this.clientInformation != null;

		if (clientHasInformation) {
			this.recoverPendingData();
		}
	},
};
</script>

<style lang="scss" scoped>
.checkbox-description {
	font-size: 14px;
}

.checkbox-description a {
	color: inherit;
	text-decoration: underline;
}

.mainBox {
	margin: 50px 0;
}

.containerBox {
	user-select: none;
	cursor: pointer;
	display: block;
	position: relative;
	margin-top: 1px;
	margin-left: 10px;
	padding: 0 20px;
	font-size: 22px;
}

.containerBox p {
	font-size: 14px;
	margin-left: 10px;
}

.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmarkBF {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border: 2px solid #113456;
}

.checkmarkBF.--error {
	border-color: #dc3545;
}

.checkmarkBF.--error ~ p {
	color: #dc3545;
}

.containerBox input[checked='checked'] ~ .checkmarkBF,
.containerBox input:checked ~ .checkmarkBF {
	background-color: #fff;
}
.checkmarkBF:after {
	content: '';
	position: absolute;
	display: none;
}

.containerBox input[checked='checked'] ~ .checkmarkBF:after,
.containerBox input:checked ~ .checkmarkBF:after {
	display: block;
}

.containerBox .checkmarkBF:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid $color-primary;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.space-error {
	padding: 6px 0;
	position: relative;
}

.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	img {
		width: 14px;
	}
	tbody {
		width: 90%;
	}
	td {
		padding-left: 0;
	}
}
@media screen and (max-width: 580px) {
	.button-confirm {
		width: 300px !important;
	}
}
</style>
